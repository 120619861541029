import React from 'react'
import proj1 from '../Assets/project-1.png'
import proj2 from '../Assets/project-2.png'
import proj3 from '../Assets/project-3.png'

const Work = () => {
  return (
    <div className='max-w-[1200px] mx-auto p-5 ' id='work'>
        <div className='pb-8'>
            <p className='text-4xl mb-3 font-bold primary-color'>Work</p>
            <p className='text-gray-400'>Check out some of my recent work</p>
        </div>
        <div className='grid sm:grid-cols-1 md:grid-cols-3 gap-4'>
        <div className='transform transition-transform duration-300 hover:scale-105 overflow-hidden shadow-lg shadow-[#040c16] group rounded-md flex justify-center items-center h-[350px] bg-cover relative'>
                <img src={proj2} layout="fill" objectFill="cover" alt=""/>
                <div className='opacity-0 group-hover:opacity-90 bg-[gray]/70 absolute inset-0 flex flex-col justify-center items-center'>
                    <span className='text-2xl font-bold text-white tracking-wider'>Project 1</span>
                    <a href="http://charl-barkhuizen.co.za/FurniCraft-Ecommerce/src/index.html" target='_blank'>
                        <button className='text-center rounded-lg px-4 py-3 m-2 bg-white text-gray-700 font-bold text-lg'>Live</button>
                    </a>
                </div>
            </div>
            
            <div className='transform transition-transform duration-300 hover:scale-105 rounded overflow-hidden shadow-lg shadow-[#040c16] group flex justify-center items-center h-[350px] bg-cover relative'>
                <img src={proj1} layout="fill" objectFill="cover" alt=""/>
                <div className='opacity-0 group-hover:opacity-90 bg-[gray]/70 absolute inset-0 flex flex-col justify-center items-center'>
                    <span className='text-2xl font-bold text-white tracking-wider'>Project 2</span>
                    <a href='http://greenmedpharmacy.co.za/IV_Bar.html' target='_blank'>
                        <button className='text-center rounded-lg px-4 py-3 m-2 bg-white text-gray-700 font-bold text-lg'>Live</button>
                    </a>
                </div>
            </div>

            
            <div className='transform transition-transform duration-300 hover:scale-105 overflow-hidden shadow-lg shadow-[#040c16] group rounded-md flex justify-center items-center h-[350px] bg-cover relative'>
                <img src={proj3} layout="fill" objectFill="cover" alt=""/>
                <div className='opacity-0 group-hover:opacity-90 bg-[gray]/70 absolute inset-0 flex flex-col justify-center items-center'>
                    <span className='text-2xl font-bold text-white tracking-wider'>Project 3</span>
                    <a href='/' target='_blank'>
                        <button className='text-center rounded-lg px-4 py-3 m-2 bg-white text-gray-700 font-bold text-lg'>Live</button>
                    </a>
                </div>
            </div>

        </div>

    </div>
  )
}

export default Work