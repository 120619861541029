import React from 'react'
import html from '../Assets/html.png'
import css from '../Assets/css.png'
import js from '../Assets/javascript.png'
import tailwind from '../Assets/tailwind.png'
import bootstrap from  '../Assets/bootstrap.png'
import react from '../Assets/react.png'
import git from '../Assets/git.png'
import github from '../Assets/github.jpg'


const Skills = () => {
  return (
    <div className='bg-black text-white md:h-[150px] max-w-[1200px] mx-auto grid grid-cols-5 place-items-center md:flex md:justify-between md:items-center p-4'>

        <h2 className='text-gray-300 text-xl md:text-4xl font-bold m-4'>
            My <br/> Tech <br/> Stack
        </h2>

        <div className='flex flex-col items-center m-4 sm:my-0 w-[40px] md:w-[100px]'>
            <img src={html} alt=''/>
            <p className='mt-2'>HTML</p>
        </div>

        <div className='flex flex-col items-center m-4 sm:my-0 w-[40px] md:w-[100px]'>
            <img src={css} alt='' width={100} height={100}/>
            <p className='mt-2'>CSS</p>
        </div>

        <div className='flex flex-col items-center m-4 sm:my-0 w-[40px] md:w-[100px]'>
            <img src={js} alt='' width={100} height={100}/>
            <p className='mt-2'>JavaScript</p>
        </div>

        <div className='flex flex-col items-center m-4 sm:my-0 w-[40px] md:w-[100px]'>
            <img src={tailwind} alt='' width={100} height={100}/>
            <p className='mt-2'>Tailwind</p>
        </div>

        <div className='flex flex-col items-center m-4 sm:my-0 w-[40px] md:w-[100px]'>
            <img src={bootstrap} alt='' width={100} height={100}/>
            <p className='mt-2'>Bootstrap</p>
        </div>

        <div className='flex flex-col items-center m-4 sm:my-0 w-[40px] md:w-[100px]'>
            <img src={react} alt='' width={100} height={100}/>
            <p className='mt-2'>React</p>
        </div>

        <div className='flex flex-col items-center m-4 sm:my-0 w-[40px] md:w-[100px]'>
            <img src={git} alt='' width={100} height={100}/>
            <p className='mt-2'>Git</p>
        </div>

        <div className='flex flex-col items-center m-4 sm:my-0 w-[40px] md:w-[100px]'>
            <img src={github} alt='' width={100} height={100}/>
            <p className='mt-2'>GitHub</p>
        </div>


    </div>
  )
}

export default Skills