import React from 'react'
import heroimage from '../Assets/hero-image.png'
import { TypeAnimation } from 'react-type-animation'


const Hero = () => {
  return (
    <div className='grid grid-cols-1 sm:grid-cols-3 gap-8 max-w-[1200px] md:h-[70vh] mx-auto py-8 ps-8 bg-black'>
       <div className='col-span-1 my-auto mx-auto w-[300px] h-auto lg:w-[400px]'>
            
                <img src={heroimage} alt='hero'/>
            

        </div> 

        <div className='col-span-2 px-5 my-auto'>

            <h1 className='text-white text-3xl sm:text-3xl lg:text-7xl font-extrabold'>
                <span className='primary-color'>
                    I'm a
                </span> <br/>
                <TypeAnimation
                sequence={[
                    "Frontend Dev",
                    1000,
                    "React Developer",
                    1000,
                    "UI/UX Designer",
                    1000,

                ]}
                wrapper="span"
                speed={50}
                repeat={Infinity}
                />
            </h1>

            <p className='text-white sm:text-lg my-6 lg:text-xl'>
                My name is Charl Barkhuizen
            </p>

            <div className='my-8'>
                <a href='#contact' className='px-6 py-3 w-full rounded-xl mr-4 bg-gradient-to-br from-blue-600 to-pink-500 text-white'>
                    Contact Me
                </a>

                
            </div>

        </div>
    </div>
  )
}

export default Hero