import React from 'react'

const Footer = () => {
  return (
    <div className='max-w-[1200px] sm:h-[150px] p-12 flex justify-between mx-auto mt-2'>
      
      
      <span className='primary-color mx-auto text-2xl'>C.Barkhuizen</span>
    </div>
  )
}

export default Footer