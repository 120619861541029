import React from 'react'
import about from '../Assets/about.jpg'




const About = () => {
  return (
    <div className='text-white max-w-[1200px] mx-auto my-12' id='about'>
        <div className='md:grid md:grid-cols-2 sm:py-16'>

            <div className='mt-4 md:mt-0 mx-auto text-left flex'>
                <div className='my-auto mx-6'>
                    <h2 className='text-4xl font-bold mb-4 primary-color'>About Me</h2>
                    <p className='text-base lg:text-xl'>

I am a passionate Web Developer with a degree in Computer Science, specializing in front end web development. I have experience with HTML, CSS, JavaScript, and have built several responsive websites using technologies such as React, Bootstrap and Tailwind. I am passionate about creating user-friendly and visually appealing interfaces, and am always looking for ways to improve my skills and stay up-to-date with the latest technologies.
<br/><br/>
In my most recent role as a front end web developer, I worked on a team to develop and maintain a large-scale web application. I was responsible for implementing new features, fixing bugs, and optimizing performance. I also had the opportunity to collaborate with designers and back end developers to ensure a seamless user experience.
<br/><br/>
I am excited to bring my skills and enthusiasm to a new opportunity, and am eager to learn and grow as a front end developer.
                    </p>
                </div>

            </div>
            <img src={about} id='aboutImg' className='mx-auto rounded-3xl my-auto py-8 md:py-0 px-4' width={350} height={350} alt=''/>

        </div>

    </div>
  )
}

export default About