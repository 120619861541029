import React, {useState} from 'react'
import {AiOutlineClose, AiOutlineMenu} from  "react-icons/ai";

const Navbar = () => {
    const [nav, setNav] = useState(false)
    const handleNav = () => {
        setNav(!nav)
    }
  return (
   
        <div className = 'bg-[#161616] h-[100px]  text-white max-w mx-auto flex justify-between items-center '>
            <h1 className='text-4xl font-bold primary-color ml-6'>C. Barkhuizen</h1>
            <ul className='hidden md:flex mr-4'>
                <li className='p-5 text-2xl '><a href='#about'>About</a></li>
                <li className='p-5 text-2xl '><a href='#work'>Work</a></li>
                <li className='p-5 text-2xl '><a href='#contact'>Contact</a></li>
            </ul>
            <div onClick={handleNav} className = 'block md:hidden mr-6'>
                {nav ? <AiOutlineClose size={20}/> : <AiOutlineMenu size={20}/>}
            </div>

            <div className={nav ? 'z-10 opacity-90 fixed h-full left-0 top-0 w-[70%] bg-[#202121] ease-in-out duration-500'
            : 'fixed left-[-100%]'}>

            
            <ul className='p-8 text-2xl mt-12'>
                
                <li className='p-2 text-2xl'><a href='#about'>About</a></li>
                <li className='p-2 text-2xl'><a href='#work'>Work</a></li>
                <li className='p-2 text-2xl'><a href='#contact'>Contact</a></li>
            </ul>
        </div>
        </div>

       

        

        

    
  )
}

export default Navbar