import React from 'react'

const Contact = () => {
  return (
    <div className='max-w-[800px] mx-auto ' id='contact'>
        <div className='flex items-center justify-center mt-6'>
        <p className='text-4xl mb-3 font-bold primary-color'>Contact Me</p>
        </div>
            
        <div className=' bg[#161616] rounded-xl'>
            <div className='p-10 mx-auto place-items'>
            <p className='text-gray-200 text-xl md:text-2xl mx-auto mb-4'>charl96barkhuizen@gmail.com<br/>+27 65 822 9792<br/>Johannesburg<br/>South Africa</p>

                <form action='https://getform.io/f/pbynnkka' method='POST'>
                    <div className='grid grid-cols-1 sm:grid-cols-2 gap-x-5 gap-y-4'>
                        <div>
                            <div className='mt-2.5'>
                                <input type='text' name='' id='' placeholder='Your Name'
                                className='bg[#161616] w-full px-4 py-4 text-gray-400 placeholder-gray-400
                                border border-gray-400 rounded-md focus:outline-none focus:border-pink-600'/>
                            </div>
                        </div>

                        <div>
                        <div className='mt-2.5 '>
                                <input type='email' name='email' id='' placeholder='Your Email'
                                className='bg[#161616] w-full px-4 py-4 text-gray-400 placeholder-gray-400
                                border border-gray-400 rounded-md focus:outline-none focus:border-pink-600'/>
                            </div>
                        </div>

                        <div className='sm:col-span-2'>
                            <div className='mt-2.5'>
                                <textarea name='message' id='' placeholder='Your Message' className='bg-[#161616] w-full text-gray-400 placeholder-gray-400
                                border border-gray-700 rounded-md focus:outline-none focus:border-pink-600' rows={4}>

                                </textarea>
                            </div>
                        </div>

                    </div>
                    
                    <div className='sm:col-span-2'>
                            <button type='submit' className='text-xl w-full p-4 mt-2 font-semibold text-white rounded-md bg-primary-color'>Send</button>
                        </div>

                    
                </form>
            </div>

        </div>


    </div>
  )
}

export default Contact